import { DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  EKeyBoardCode,
  INITIAL_PAGINATION_SiZE,
  PATH_CREATE_POPUP,
  PATH_POPUP_DETAIL_NO_ID,
} from '@configs'
import {
  EPopupManagementTable,
  EPopupStatus,
  LIST_POPUP_STATE,
  LIST_POPUP_STATUS,
} from '@pages'
import { Card, message, Skeleton, Tooltip, Typography } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Input, SharedTable } from 'src/common'
import styled from 'styled-components'

import { IListPopupResponse } from '@interfaces'
import { deletePopup, getListPopup, useAppDispatch } from '@redux'
import { formatDate } from '@utils'
import queryString from 'query-string'
import { useDebounce } from 'src/hooks/useDebounce'
import { handleAPIError } from 'src/utils/handleApiError'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'

export const PopupManagement = () => {
  const { search } = useLocation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION_SiZE)
  const debouncedValue = useDebounce<string | null>(searchValue.trim(), 500)
  const [localPage, setLocalPage] = useState(1)
  const [popupData, setListPopupData] = useState<IListPopupResponse | null>(
    null
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<string>('')
  const [filterState, setFilterState] = useState<string>('')
  const [filterStatus, setSFilterStatus] = useState<string>('')

  const onSearchPopup = (page?: number) => {
    const localURlQuery: any = {
      page: page ?? 1,
      limit: pageSize,
    }
    localURlQuery.search = searchValue.trim()

    if (filterState) {
      localURlQuery.state = filterState
    }

    if (filterStatus) {
      localURlQuery.status = filterStatus
    }

    const stringifyQuery = queryString.stringify(localURlQuery)
    setSearchParams(stringifyQuery)
  }

  useEffect(() => {
    if (debouncedValue !== null || filterState || filterStatus) {
      onSearchPopup()
    }
  }, [debouncedValue, filterState, filterStatus])

  const getAllPopups = async () => {
    setIsLoading(true)
    try {
      if (search) {
        const parsedQuery = queryString.parse(search)

        const response = await dispatch(
          getListPopup({
            ...parsedQuery,
          })
        ).unwrap()
        setListPopupData(response)
        return
      }
      const response = await dispatch(getListPopup()).unwrap()
      setListPopupData(response)
    } catch (error: any) {
      handleAPIError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAllPopups()
  }, [dispatch, search, pageSize, localPage])

  const columns = useMemo(() => {
    return [
      {
        title: EPopupManagementTable.NO,
        dataIndex: 'no',
        key: 'id',
        ellipsis: true,
        width: '15%',
        align: 'center',
      },
      {
        title: EPopupManagementTable.POPUP_NAME,
        dataIndex: 'popupName',
        key: 'popupName',
        render: (text: string) => <TextWrapper>{text}</TextWrapper>,
        align: 'center',
        width: '25%',
      },
      {
        title: EPopupManagementTable.PUBLISHED_DATE,
        dataIndex: 'publishedDate',
        key: 'publishedDate',
        render: (text: string) => (
          <TextWrapper className="truncate">{text}</TextWrapper>
        ),
        align: 'center',
        width: '30%',
      },
      {
        title: EPopupManagementTable.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => (
          <Typography.Text
            style={{
              color: text === EPopupStatus.ACTIVE ? '#00A86B' : '#FF4D4F',
            }}
          >
            {text}
          </Typography.Text>
        ),
        align: 'center',
        width: '30%',
      },
      {
        title: EPopupManagementTable.STATE,
        dataIndex: 'state',
        key: 'state',
        render: (text: string) => <Typography.Text>{text}</Typography.Text>,
        align: 'center',
        width: '30%',
      },
      {
        title: '',
        key: 'Edit',
        render: (_: any, record: { key: number | string }) => (
          <div
            onClick={() => {
              navigate(`${PATH_POPUP_DETAIL_NO_ID}/${record.key}`)
            }}
            className="flex space-x-4 items-center justify-center"
          >
            <Tooltip title={t('common:view_detail')}>
              <EyeOutlined className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]" />
            </Tooltip>
          </div>
        ),
        ellipsis: true,
        width: '10%',
        align: 'center',
      },
      {
        title: '',
        key: 'Delete',
        render: (_: any, record: { id: any; key: number }) => (
          <div
            onClick={() => {
              setOpenConfirmDeleteModal(true)
              setSelectedRow(record.key.toString())
            }}
            className="flex space-x-4 items-center justify-center"
          >
            <Tooltip title={t('common:delete')}>
              <DeleteOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
              />
            </Tooltip>
          </div>
        ),
        ellipsis: true,
        width: '10%',
        align: 'center',
      },
    ]
  }, [])

  const listPopup = useMemo(() => {
    if (!popupData) return []
    return popupData.popups.items.map((item, index) => {
      return {
        key: item.id,
        no: index + 1,
        popupName: item.name,
        publishedDate: item.publishedAt
          ? formatDate(item.publishedAt, 'DD/MM/YYYY')
          : item.scheduleDate
          ? formatDate(item.scheduleDate, 'DD/MM/YYYY')
          : 'N/A',
        status: item.isActive ? EPopupStatus.ACTIVE : EPopupStatus.INACTIVE,
        state: item.state,
      }
    })
  }, [popupData])

  const handleDeletePopup = async (id: string | number) => {
    try {
      const response = await dispatch(deletePopup(id)).unwrap()
      if (response) {
        message.success(response.message)
      }
      await setLocalPage(1)
      const params = new URLSearchParams(search)
      if (params.get('page') === '1') {
        getAllPopups()
      } else {
        await onSearchPopup(1)
      }
    } catch (error: any) {
      handleAPIError(error)
    }
  }

  return (
    <div>
      <Card>
        <div>
          <div className="flex justify-between gap-8">
            <div className="max-w-1/2 border rounded-sm w-full px-4 py-2 shadow-md flex flex-col gap-4">
              <Typography.Text className="font-semibold text-[24px]">
                Current Published/Scheduled pop up:{' '}
                {popupData?.popupScheduledAndPublish || 0}/
                {popupData?.popupLimit || 0}
              </Typography.Text>
            </div>
          </div>
          <div className="flex items-center justify-between py-[1.25rem] w-full">
            <div className="max-w-[500px] flex-1 flex-row flex">
              <Input
                placeholder={t('common:searchByName')}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === `${EKeyBoardCode.ENTER}`) {
                    onSearchPopup()
                  }
                }}
                prefix={
                  <span className="flex items-center justify-center pl-[0.75rem]">
                    <SearchOutlined />
                  </span>
                }
              />
            </div>
            <div className="flex items-center gap-[16px]">
              <ShareSelectInput
                data={LIST_POPUP_STATE}
                value={filterState}
                placeholder="State"
                style={{ width: '160px' }}
                onChange={(value) => {
                  setFilterState(value)
                }}
              />

              <ShareSelectInput
                data={LIST_POPUP_STATUS}
                value={filterStatus}
                placeholder="Status"
                style={{ width: '160px' }}
                onChange={(value) => {
                  setSFilterStatus(value)
                }}
              />

              <Button
                type="primary"
                onClick={() => {
                  navigate(PATH_CREATE_POPUP)
                }}
              >
                Create new popup
              </Button>
            </div>
          </div>

          {isLoading ? (
            <Skeleton paragraph={{ rows: 8 }} />
          ) : (
            <TableWrapper>
              <SharedTable
                columns={columns as any}
                dataSource={listPopup}
                hasIndexColumn={false}
                paginationProps={{
                  total: popupData?.popups.total as any,
                  pageSize: pageSize as any,
                  current: localPage as any,
                  showSizeChanger: false,
                  onChange(page) {
                    onSearchPopup(page)
                    setLocalPage(page)
                  },
                }}
              />
            </TableWrapper>
          )}

          <ConfirmDeleteModal
            open={openConfirmDeleteModal}
            isLoading={false}
            title={t('common:delete_popup')}
            content={t('common:delete_popup_content')}
            onClose={() => setOpenConfirmDeleteModal(false)}
            onDelete={() => {
              handleDeletePopup(selectedRow as string)
              setOpenConfirmDeleteModal(false)
            }}
          />
        </div>
      </Card>
    </div>
  )
}

const TextWrapper = styled(Typography.Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
`

const TableWrapper = styled.div`
  .ant-table-wrapper,
  .ant-table-content {
    padding: 0 !important;
    margin: 0 !important;
  }
`
